import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import SimposioLogo from "../images/logos/smstem-logo.png"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/i-smstem.jpeg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>'I Simpósio Brasileiro de Mulheres em STEM (SMSTEM)'</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "I Simpósio Brasileiro de Mulheres em STEM (SMSTEM)"
        date = "Março/2020"
        description = "Apresentação de pôster no I Simpósio de Mulheres em STEM realizado no Instituto Tecnológico de Aeronáutica (ITA). Foram dois dias de evento com rodas de conversa, palestras, mesas redondas, workshops e apresentação de pôsteres junto a projetos de todo país."        
      />
      <div className="partner-container">
        <div className="partner-title">OFERECIMENTO</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={ SimposioLogo } alt="" className="partner-logo"/>
                <p className="partner-name">I SMSTEM</p>
            </div>
        </div>
      </div>
      <MyFooter />
  </div>
)

export default EventsPage
